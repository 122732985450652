// eslint-disable-next-line no-useless-escape
const SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g;
const MOZ_HACK_REGEXP = /^moz([A-Z])/;

// eslint-disable-next-line func-names
const camelCase = function (name) {
  return name.replace(SPECIAL_CHARS_REGEXP, (_, separator, letter, offset) => (offset ? letter.toUpperCase() : letter)).replace(MOZ_HACK_REGEXP, 'Moz$1');
};

// eslint-disable-next-line func-names
export const getStyle = function (element, styleName) {
  // eslint-disable-next-line no-undef
  if (!element || !styleName) return null;
  // eslint-disable-next-line no-param-reassign
  styleName = camelCase(styleName);
  if (styleName === 'float') {
    // eslint-disable-next-line no-param-reassign
    styleName = 'cssFloat';
  }
  try {
    const computed = document.defaultView.getComputedStyle(element, '');
    return element.style[styleName] || computed ? computed[styleName] : null;
  } catch (e) {
    return element.style[styleName];
  }
};

export function debounce(fn, delay) {
  let timer;
  // eslint-disable-next-line func-names
  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}
