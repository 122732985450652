<template>
<div>
  <div class="wrap"
       @mouseenter="handleDivMouseEnter($event)"
       @mouseleave="handleDivMouseleave($event)">
    <div class="text">{{ text }}</div>
  </div>
  <el-tooltip ref="tooltip" :content="tooltipContent"></el-tooltip>
</div>

</template>

<script>
import { getStyle, debounce } from './utils';

export default {
  name: 'TextEllipsis',
  enName: '文本溢出展示',
  props: {
    text: [String, Number],
  },
  data() {
    return {
      tooltipContent: '',
    };
  },
  methods: {
    /** @desc 鼠标移入 */
    handleDivMouseEnter(event) {
      const { target } = event;

      const cellChild = event.target.querySelector('.text');
      const range = document.createRange();
      range.setStart(cellChild, 0);
      range.setEnd(cellChild, cellChild.childNodes.length);
      const rangeWidth = range.getBoundingClientRect().width;

      const padding = (parseInt(getStyle(target, 'paddingLeft'), 10) || 0) + (parseInt(getStyle(target, 'paddingRight'), 10) || 0);

      if ((rangeWidth + padding > cellChild.offsetWidth || cellChild.scrollWidth > cellChild.offsetWidth) && this.$refs.tooltip) {
        const { tooltip } = this.$refs;
        this.tooltipContent = target.innerText || target.textContent;
        tooltip.referenceElm = target;
        // eslint-disable-next-line no-unused-expressions
        tooltip.$refs.popper && (tooltip.$refs.popper.style.display = 'none');
        tooltip.doDestroy();
        tooltip.setExpectedState(true);
        this.activateTooltip(tooltip);
      }
    },
    /** @desc 鼠标移出 */
    handleDivMouseleave(event) {
      const { tooltip } = this.$refs;
      if (tooltip) {
        tooltip.setExpectedState(false);
        tooltip.handleClosePopper();
      }
    },
  },
  created() {
    this.activateTooltip = debounce((tooltip) => tooltip.handleShowPopper(), 50);
  },
};
</script>

<style lang="less" scoped>
.wrap,
.text{
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}
</style>
