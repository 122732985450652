<template>
  <div class="biz-title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Title',
  enName: '表格标题',
  props: {
    title: {
      type: String,
      default: 'this is a title',
    },
  },
};
</script>

<style lang="less" scoped>
.biz-title{
 font-size: 16px;
 font-weight: 600;
 line-height: 20px;
 margin-bottom: 10px;
}
</style>
