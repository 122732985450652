var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "wrap",
          on: {
            mouseenter: function ($event) {
              return _vm.handleDivMouseEnter($event)
            },
            mouseleave: function ($event) {
              return _vm.handleDivMouseleave($event)
            },
          },
        },
        [_c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))])]
      ),
      _c("el-tooltip", {
        ref: "tooltip",
        attrs: { content: _vm.tooltipContent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }