<template>
  <div class="empty">暂无图片!</div>
</template>

<script>
export default {
  name: 'Empty',
  enName: '空文本展示框',
};
</script>
<style lang="less" scoped>
.empty{
  width: 100%;
  min-height: 150px;
  border-radius: 4px;
  border: 1px dashed #ededed;
  text-align: center;
  line-height: 150px;
  font-size: 14px;
  color: #333;
}
</style>
